import request from '@/utils/request';

export async function login(data: {
  identifier: string;
  password: string;
  shop: string;
  remember?: boolean;
}) {
  return request('/auth/local', {
    method: 'POST',
    data,
    errorHandler: async error => {
      const { response } = error;
      return response.json();
    },
  });
}

export async function logout() {
  return request('/logout', {
    method: 'POST',
  });
}

export async function forgotPassword(email: string) {
  return request('/auth/forgot-password', {
    method: 'POST',
    data: { email },
  });
}

export async function resetPassword(data: {
  password: string;
  passwordConfirmation: string;
  code: string;
}) {
  return request('/auth/reset-password', {
    method: 'POST',
    data,
  });
}

export async function getUserInfo(options?: { errorHandler?: () => {} }) {
  return request('/me', options);
}

export async function updateUserInfo(updates: any) {
  const { id: removeId, ...data } = updates;
  return request(`/me`, {
    method: 'PUT',
    data,
  });
}

export async function verifySMTP(data: { pass: string; email: string }) {
  return request(`/emails/verify`, {
    method: 'POST',
    data,
  });
}

export async function saveSMTP(data: { email: string; pass: string }) {
  return request(`/emails/config`, {
    method: 'POST',
    data,
  });
}

export async function checkOAuth() {
  return request(`/emails/oauth`, {
    method: 'GET',
  });
}

export async function getOAuthToken(code: string) {
  return request(`/emails/code`, {
    method: 'POST',
    data: { code },
  });
}

export async function changePassword(data: { password: string; new_password: string }) {
  return request(`/me/password`, {
    method: 'POST',
    data,
  });
}

export async function signup(data: any) {
  return request('/auth/local/register', {
    method: 'POST',
    data,
    errorHandler: async error => {
      const { response } = error;
      return response.json();
    },
  });
}

export async function confirmEmail(confirmation: string) {
  return request('/auth/email-confirmation', {
    params: { confirmation },
    errorHandler: error => {
      throw error;
    },
  });
}

export async function sendConfirmationEmail(email: string) {
  return request('/auth/send-email-confirmation', {
    method: 'POST',
    data: { email },
  });
}

export async function agreeUserAgreement() {
  return request('/me/agreeUserAgreement', {
    method: 'POST',
  });
}

export async function postUserStatus(data: any) {
  return request('/me/status', {
    method: 'POST',
    data,
  });
}
