export default {
  'shipment.list_title.ocean_import': 'Ocean Import: {view}s',
  'shipment.list_title.ocean_export': 'Ocean Export: {view}s',
  'shipment.list_title.air_import': 'Air Import: {view}s',
  'shipment.list_title.air_export': 'Air Export: {view}s',
  'shipment.list_title.misc': 'Operation List',
  'shipment.list_title.truck': 'Truck List',
  'shipment.list_title.ocean_import_shipments': 'Ocean Import Shipments',
  'shipment.list_title.ocean_export_shipments': 'Ocean Export Shipments',
  'shipment.list_title.air_import_shipments': 'Air Import Shipments',
  'shipment.list_title.air_export_shipments': 'Air Export Shipments',
  'shipment.list_title.misc_shipments': 'Operation List',
  'shipment.list_title.truck_shipments': 'Truck List',
  'shipment.list_title.all_shipments': 'All Shipments',
  'shipment.list_title.all': 'All {view}s',
  'shipment.list_title.my_all_shipments': 'My Shipments',
  'shipment.list_title.my_ocean_import_shipments': 'My Ocean Import Shipments',
  'shipment.list_title.my_ocean_export_shipments': 'My Ocean Export Shipments',
  'shipment.list_title.my_air_import_shipments': 'My Air Import Shipments',
  'shipment.list_title.my_air_export_shipments': 'My Air Export Shipments',
  'shipment.list_title.my_misc': 'My Operation List',
  'shipment.list_title.my_truck_shipments': 'My Truck List',
  'shipment.list_title.edi_ocean_import_shipments': 'Ocean Import EDI List',
  'shipment.list_title.edi_air_import_shipments': 'Air Import EDI List',
  'shipment.type.ocean_import': 'Ocean Import',
  'shipment.type.ocean_export': 'Ocean Export',
  'shipment.type.air_import': 'Air Import',
  'shipment.type.air_export': 'Air Export',
  'shipment.information': 'Shipment Information',
  'shipment.status_requirements': 'Status & Requirements',
  'shipment.type.misc': 'Misc',
  'shipment.type.truck': 'Truck',
  'shipment.duplicate_eta_etd': 'Duplicate ETD/ETA',
  'shipment.date_type.created_at': 'Created At',
  'shipment.date_type.etd': 'ETD',
  'shipment.date_type.eta': 'ETA',
  'shipment.ship_type':
    '{ship_type, select,' +
    'ocean {Ocean}' +
    'air {Air}' +
    'truck {Truck}' +
    'warehouse {Warehouse}' +
    'courier {Courier}' +
    'others {Others}' +
    'other {Other}' +
    '}',
  'shipment.field.service_type': 'Service Type',
  'shipment.field.volumetric_weight': 'Volumetric Weight',
  'shipment.field.shipment_number': 'Ref No.',
  'shipment.field.customer_booking_no': 'Customer Booking No.',
  'shipment.shipment_number': 'Shipment Number',
  'shipment.field.hbl_no': 'HB/L No.',
  'shipment.field.hawb_no': 'HAWB No.',
  'shipment.field.hbl_count': 'HB/L Cnt',
  'shipment.field.hawb_count': 'HAWB Cnt',
  'shipment.field.mbl_no': 'MB/L No.',
  'shipment.field.mawb_no': 'MAWB No.',
  'shipment.field.mbl_type': 'B/L Type',
  'shipment.field.ship_type': 'Ship Type',
  'shipment.field.post_at': 'Post Date',
  'shipment.field.frt_location': 'FRT Location',
  'shipment.field.mode': 'Ship Mode',
  'shipment.field.final_port_of_discharge': 'Final POD',
  'shipment.field.term': 'Term',
  'shipment.field.etd': 'ETD',
  'shipment.field.etd_atd': 'ETD / ATD',
  'shipment.field.eta_ata': 'ETA / ATA',
  'shipment.field.port_last_free_date': 'Port Last Free Date',
  'shipment.field.eta': 'ETA',
  'shipment.field.op': 'OP',
  'shipment.field.est': 'EST',
  'shipment.field.atd': 'ATD',
  'shipment.field.ata': 'ATA',
  'shipment.field.etb': 'ETB',
  'shipment.field.age': 'Age',
  'shipment.field.chargeable_weight': 'Chargeable Weight',
  'shipment.field.vessel': 'Vessel',
  'shipment.field.vessel_imo': 'Vessel IMO',
  'shipment.field.vessel_flag': 'Vessel Flag',
  'shipment.field.voyage': 'Voyage',
  'shipment.field.flight_no': 'Flight No.',
  'shipment.field.ship_or_flight': 'Ship/Flight',
  'shipment.field.commodity': 'Commodity',
  'shipment.field.pieces': 'Packages',
  'shipment.field.piece_count': 'Piece Count',
  'shipment.field.weight': 'Weight',
  'shipment.field.gross_weight': 'Gross Weight',
  'shipment.field.measure': 'Measurement(CBM)',
  'shipment.field.shipper': 'Shipper',
  'shipment.field.consignee': 'Consignee',
  'shipment.field.notify': 'Notify Party',
  'shipment.field.additional_notify': 'Additional Notify Party',
  'shipment.field.agent': 'Agent',
  'shipment.field.forwarding_agent': 'Forwarding Agent',
  'shipment.field.go_date': 'G.O. Date',
  'shipment.field.sub_bl_no': 'Sub B/L No.',
  'shipment.field.invoice_due_date': 'Invoice Due Date',
  'shipment.field.invoice_date': 'Invoice Date',
  'shipment.field.invoice_date.placeholder': 'Please input invoice date',
  'shipment.field.carrier': 'Carrier',
  'shipment.field.airline': 'Airline',
  'shipment.field.trucker': 'Trucker',
  'shipment.field.broker': 'Broker',
  'shipment.field.cargo_location': 'Cargo Location',
  'shipment.field.coloader': 'Co-Loader',
  'shipment.field.agent_ref_no': 'Agent Ref No.',
  'shipment.field.final_destination': 'Final Destination',
  'shipment.field.routing_term': 'Routing',
  'shipment.field.pickup_number': 'Pickup No.',
  'shipment.field.commodity_info': 'Commodity Description',
  'shipment.field.container_return_address': 'Container Return Address',
  'shipment.field.mbl_payment': 'MB/L Payment',
  'shipment.field.hbl_payment': 'HB/L Payment',
  'shipment.field.mbl_revenue': 'MB/L Revenue',
  'shipment.field.mawb_revenue': 'MAWB Revenue',
  'shipment.field.mbl_cost': 'MB/L Cost',
  'shipment.field.mawb_cost': 'MAWB Cost',
  'shipment.field.mbl_profit': 'MB/L Profit',
  'shipment.field.mawb_profit': 'MAWB Profit',
  'shipment.field.mbl_release': 'MB/L Release',
  'shipment.field.mawb_release': 'MAWB Release',
  'shipment.field.hbl_release': 'HB/L Release',
  'shipment.field.hbl_amount': 'HB/L Amount',
  'shipment.field.hawb_amount': 'HAWB Amount',
  'shipment.field.hbl_revenue': 'HB/L Revenue',
  'shipment.field.hawb_revenue': 'HAWB Revenue',
  'shipment.field.hbl_cost': 'HB/L Cost',
  'shipment.field.hawb_cost': 'HAWB Cost',
  'shipment.field.hbl_profit': 'HB/L Profit',
  'shipment.field.hawb_profit': 'HAWB Profit',
  'shipment.field.profit_amount': 'Profit Amount',
  'shipment.field.profit_percentage': 'Profit Percentage',
  'shipment.field.profit_margin': 'Profit Margin',
  'shipment.field.profit': 'Profit',
  'shipment.field.profit_amt': 'Profit Amt',
  'shipment.field.cost': 'Cost',
  'shipment.field.cost_in_currency': 'Cost ({currency})',
  'shipment.field.revenue': 'Revenue',
  'shipment.field.revenue_in_currency': 'Revenue ({currency})',
  'shipment.field.shipment_profit': 'Shipment Profit',
  'shipment.field.mbl_amount': 'MB/L Amount',
  'shipment.field.mawb_amount': 'MAWB Amount',
  'shipment.field.mbl_release.original': 'Original',
  'shipment.field.mbl_release.telex': 'Telex',
  'shipment.field.mbl_release.seaway_bill': 'Seaway Bill',
  'shipment.field.mbl_release.e_bill': 'e-Bill',
  'shipment.field.hbl_release.original': 'Original',
  'shipment.field.hbl_release.telex': 'Telex',
  'shipment.field.hbl_release.seaway_bill': 'Seaway Bill',
  'shipment.field.hbl_release.e_bill': 'e-Bill',
  'shipment.field.shipment_containers': 'Containers',
  'shipment.field.shipment_containers.pieces': 'Packages',
  'shipment.field.shipment_containers.name': 'Container No.',
  'shipment.field.shipment_containers.seal_number': 'Container Seal No.',
  'shipment.field.shipment_containers.mark': 'Container Marks',
  'shipment.field.shipment_containers.size': 'Container Size',
  'shipment.field.shipment_containers.type': 'Container Type',
  'shipment.field.shipment_containers.weight': 'Container Weight',
  'shipment.field.shipment_containers.measure': 'Container Measurement',
  'shipment.field.freight_charge_weight': 'Charging Weight',
  'shipment.field.freight_rate': 'Charging Rate',
  'shipment.field.freight_charge_level': 'Charging Level',
  'shipment.field.freight_charge': 'Total Freight Charge',
  'shipment.field.flight_duration': 'Flight Duration',
  'shipment.field.operator': 'Operator',
  'shipment.field.cy_location': 'CY Location',
  'shipment.field.charge_item': 'Charge Item',
  'shipment.field.cfs_location': 'CFS Location',
  'shipment.field.cfs_contact': 'CFS Contact ',
  'shipment.field.cfs_phone': 'CFS Phone',
  'shipment.field.shipping_agency': 'Shipping Agency',
  'shipment.field.place_of_delivery': 'Place of Delivery',
  'shipment.field.on_board_date': 'On Board Date',
  'shipment.field.place_of_delivery_eta': 'Place of Delivery ETA',
  'shipment.field.final_eta': 'Final ETA',
  'shipment.field.carrier_release_date': 'Carrier Release Date',
  'shipment.field.carrier_release_date.required': 'Please input Carrier Release Date',
  'shipment.field.terminal_release_date': 'Terminal Release Date',
  'shipment.field.terminal_release_date.required': 'Please input Terminal Release Date',
  'shipment.field.freight_payment': 'Freight',
  'shipment.field.truck_freight_payment': 'Truck Freight',
  'shipment.field.obl_type': 'OB/L Type',
  'shipment.field.obl_type.original': 'ORIGINAL BILL OF LADING',
  'shipment.field.obl_type.telex': 'TELEX',
  'shipment.field.obl_type.seaway_bill': 'SEAWAY BILL',
  'shipment.field.obl_type.e_bill': 'E-BILL',
  'shipment.field.container_return_location': 'Return Location',
  'shipment.field.place_of_receipt': 'Place of Receipt',
  'shipment.field.place_of_receipt_etd': 'Place of Receipt ETD',
  'shipment.field.it_no': 'IT No.',
  'shipment.field.it_no.required': 'Please input IT No.',
  'shipment.field.it_date': 'IT Date',
  'shipment.field.it_date.required': 'Please input IT Date',
  'shipment.field.i_t_place': 'I.T. Place',
  'shipment.field.i_t_place.required': 'Please input I.T. Place',
  'shipment.field.it_place': 'IT Issued Location',
  'shipment.field.ccn': 'CPRS/Address',
  'shipment.field.release_date': 'Released Date',
  'shipment.field.entry_date': 'Receipt Date',
  'shipment.field.flight': 'Flight',
  'shipment.field.port_of_transit': 'Transhipment Port',
  'shipment.field.transit_carrier': 'Transit Carrier',
  'shipment.field.transit_flight': 'Transit Flight',
  'shipment.field.transit_etd': 'Transhipment ETD',
  'shipment.field.transit_eta': 'Transhipment ETA',
  'shipment.field.ams_no': 'AMS No.',
  'shipment.field.isf_no': 'ISF No.',
  'shipment.field.customer': 'Customer',
  'shipment.field.bill_to': 'Bill To',
  'shipment.field.bill_date': 'Bill Date',
  'shipment.field.sales': 'Sales',
  'shipment.field.cycfs_location': 'CY/CFS Location',
  'shipment.field.freight_location': 'Freight Location',
  'shipment.field.delivery_address': 'Delivery Address',
  'shipment.field.available_date': 'Available Date',
  'shipment.field.delivery_date': 'Delivery Date',
  'shipment.field.delivery_location': 'Delivery Location',
  'shipment.field.empty_pickup': 'Empty Pickup',
  'shipment.field.additional_delivery_location': 'Additional Delivery Location',
  'shipment.field.last_free_date': 'Last Free Date',
  'shipment.field.rail_company': 'Rail',
  'shipment.field.sales_type': 'Sales Type',
  'shipment.field.sales_type.co_load': 'CO-LOAD',
  'shipment.field.sales_type.free_cargo': 'FREE CARGO',
  'shipment.field.sales_type.nomi': 'NOMI',
  'shipment.field.incoterms': 'Incoterms',
  'shipment.field.cargo_type': 'Cargo Type',
  'shipment.field.cargo_type.aut': 'AUTOMOBILE (NON-HAZ)',
  'shipment.field.cargo_type.bat': 'BATTERY',
  'shipment.field.cargo_type.nor': 'GENERAL CARGO',
  'shipment.field.cargo_type.dgg': 'HAZARDOUS',
  'shipment.field.cargo_type.ref': 'REFRIGERATED',
  'shipment.field.cargo_type.spc': 'SPECIAL',
  'shipment.field.is_door_move': 'Door Move',
  'shipment.field.is_customs_clearance': 'C.Clearance',
  'shipment.field.is_customs_hold': 'C.Hold',
  'shipment.field.customs_release_date': 'Customs Released Date',
  'shipment.field.customs_release_date.required': 'Please input Customs Release Date',
  'shipment.field.telex_release_received_date': 'Telex Release Received Date',
  'shipment.field.original_bl_received_date': 'OB/L Received Date',
  'shipment.field.original_bl_released_date': 'OB/L Released Date',
  'shipment.field.is_release_order_required': 'Release Order Required',
  'shipment.field.door_delivery_date': 'Door Delivered',
  'shipment.field.arrival_notice_sent_date': 'Arrival Notice Sent',
  'shipment.field.delivery_order_sent_date': 'Delivery Order Sent',
  'shipment.field.po_no': 'PO No.',
  'shipment.field.commercial_invoice_no': 'Commercial Invoice No.',
  'shipment.field.rate_class': 'Rate Class',
  'shipment.field.dv_carriage': 'D.V. Carriage',
  'shipment.field.dv_customs': 'D.V. Customs',
  'shipment.field.air_insurance': 'Insurance',
  'shipment.field.wt_val_charges': 'WT/VAL',
  'shipment.field.other_charges': 'Other Charges',
  'shipment.field.party_term': 'Carrier/Agent',
  'shipment.field.mark': 'Mark',
  'shipment.field.pickup_date': 'Pick Up Date',
  'shipment.field.soc_address': 'SOC Return Address',
  'shipment.field.nature_quantity_of_goods': 'Nature and Quantity of Goods',
  'shipment.field.arrival_notice_remark': 'Arrival Notice Remark',
  'shipment.field.delivery_order_remark': 'Delivery Order Remark',
  'shipment.field.color': 'Color',
  'shipment.field.ar_total': 'A/R TOTAL',
  'shipment.field.ap_total': 'A/P TOTAL',
  'shipment.field.dc_total': 'D/C TOTAL',
  'shipment.field.ar_balance': 'A/R BAL',
  'shipment.field.ap_balance': 'A/P BAL',
  'shipment.field.dc_balance': 'D/C BAL',
  'shipment.field.incl_archived': 'Incl. Archived',
  'shipment.field.todos': 'To-dos',
  'shipment.todos.list.title': 'Todo List',
  'shipment.field.vessel_voyage': 'Vessel/Voyage',
  'shipment.field.due_date': 'Due Date',
  'shipment.field.type': 'Type',
  'shipment.field.delivery_notes': 'Delivery Notes',
  'shipment.field.delivery_notes.placeholder': 'Enter notes here...',
  'shipment.field.is_direct': 'Direct Master',
  'shipment.field.customer_ref_no': 'Customer Ref No.',
  'shipment.field.post_date': 'Post Date',
  'shipment.field.bl_no': 'BL No.',
  'shipment.field.bl_type': 'BL Type',
  'shipment.field.release_to': 'Release To',
  'shipment.field.doc_cut_off_time': 'Doc Cut-Off Date',
  'shipment.field.port_cut_off_time': 'Port Cut-Off Date',
  'shipment.field.vgm_cut_off_time': 'VGM Cut-Off Date',
  'shipment.field.rail_cut_off_time': 'Rail Cut-Off Date',
  'shipment.field.wh_cut_off_time': 'W/H Cut-Off Date',
  'shipment.field.loading_location': 'Loading Location',
  'shipment.field.origin_terminal': 'Origin Terminal',
  'shipment.field.hbl_type': 'HB/L Type',
  'shipment.field.hawb_type': 'HAWB Type',
  'shipment.field.itn_no': 'ITN No.',
  'shipment.field.is_rated_bl': 'Rated B/L',
  'shipment.field.date_type': 'Date Type',
  'shipment.field.carrier_booking_no': 'Carrier Bkg. No.',
  'shipment.field.pickup_location': 'Pickup Location',
  'shipment.field.pickup_equipment_location': 'Pickup Equipment Location',
  'shipment.field.equipment_origin': 'Equipment Origin',
  'shipment.field.vsl_id': 'Vessel ID',
  'shipment.field.scn': 'Shipment Control Number',
  'shipment.field.sa_id': 'Shipping Agent ID',
  'shipment.field.shipment_container_qty': 'Container/Qty',
  'shipment.field.ssl_scac': 'SSL SCAC',
  'shipment.field.aes_no': 'AES No.',
  'shipment.field.booking_requested_date': 'Booking Requested Date',
  'shipment.field.booking_confirmed_date': 'Booking Confirmed Date',
  'shipment.field.origin_trucker': 'Origin Trucker',
  'shipment.field.delivery_trucker': 'Delivery Trucker',
  'shipment.field.ci_pl_received_date': 'CI/PL Received Date',
  'shipment.field.aes_cut_off_time': 'AES Cut-Off',
  'shipment.field.isf_filed_date': 'ISF Filed',
  'shipment.field.hbl_issued_date': 'HB/L Issued',
  'shipment.field.pre_alert_issued_date': 'Pre-Alert Issued',
  'shipment.field.document_approved_date': 'Document Approved',
  'shipment.field.ams_accepted_date': 'AMS Accepted',
  'shipment.field.equipment_pickup_date': 'Equipment Picked Up',
  'shipment.field.equipment_delivery_date': 'Equipment Delivered',
  'shipment.field.equipment_return_date': 'Equipment Returned',
  'shipment.field.quotation': 'Quotation',
  'shipment.field.volume_weight': 'Volume Weight',
  'shipment.field.obl_telex_release_received_date': 'OBL/TELEX Release Received Date',
  'shipment.field.pickup_delivery_location': 'Pickup/Delivery Location',
  'shipment.add_commodity': 'Add Commodity',
  'shipment.field.shipper_gross_weight': 'Gross Weight (Shipper)',
  'shipment.field.shipper_chargeable_weight': 'Chargeable Weight (Shipper)',
  'shipment.field.consignee_gross_weight': 'Gross Weight (Consignee)',
  'shipment.field.consignee_chargeable_weight': 'Chargeable Weight (Consignee)',
  'shipment.field.freight_released': 'Freight Released',
  'shipment.field.entry_doc_sent_date': 'Customs Doc Sent Date',
  'shipment.field.entry_doc_sent': 'Customs Doc Sent',
  'shipment.related_documents': 'Related Documents',
  'shipment.added.success': 'Successfully added new shipment',
  'shipment.created.success': 'Successfully created shipment',
  'shipment.updated.success': 'Successfully updated shipment',
  'shipment.sent_to': 'Sent To',
  'shipment.sent_at': 'Sent AT',
  'shipment.generated.success': 'Successfully generated',
  'shipment.generated_release_order.success': 'Successfully generated Release Order',
  'shipment.generated_release_order.confirm.title':
    'Are you sure to generate Release Order for this shipment?',
  'shipment.generated_delivery_order.confirm.title':
    'Are you sure to generate Delivery Order for this shipment?',
  'shipment.submit.success.title': 'Are you sure you want to submit this shipment?.',
  'shipment.duplicate.success.title': 'Are you sure you want to duplicate this shipment?',
  'shipment.transfer.success.title':
    'Are you sure you want to transfer this shipment to another office?',
  'shipment.delete.success.title': 'Are you sure you want to delete this shipment?',
  'shipment.archive.success.title': 'Are you sure you want to archive this shipment?',
  'shipment.archive.success.content': 'You will not be able to make changes to it after archiving.',
  'shipment.unarchive.success.title': 'Are you sure you want to unarchive this shipment?',
  'shipment.unarchive.success.content': 'OP can make changes to the shipment again',
  'shipment.hbl.delete.confirm.title': 'Are you sure you want to delete this HBL?',
  'shipment.hbl.duplicate.confirm.title':
    'Are you sure you want to duplicate this HBL under this MBL?',
  'shipment.hbl.duplicate.copies': 'Number of copies',
  'shipment.hbl.discard.confirm.title': 'You have unsaved changes.',
  'shipment.hbl.discard.confirm.content': 'Are you sure to discard the changes?',
  'shipment.copy_hbl.confirm': 'Do you want to copy this change to all HBL(s)?',
  'shipment.copy_mbl.confirm': 'Do you want to copy this change to MBL?',
  'shipment.submit.success': 'Successfully Submitted Shipment',
  'shipment.delete.success': 'Successfully Deleted Shipment',
  'shipment.hbl.delete.success': 'Successfully Deleted HBL',
  'shipment.hbl.duplicated.success': 'Successfully Duplicated HBL',
  'shipment.archived.success': 'Successfully Archived Shipment',
  'shipment.unarchived.success': 'Successfully Unarchived Shipment',
  'shipment.duplicated.success': 'Successfully Duplicated Shipment',
  'shipment.transferred.success': 'Successfully Transferred Shipment to another office',
  'shipment.generated_arrival_notice.success': 'Successfully generated Arrival Notice',
  'shipment.generated_arrival_notice.confirm.title':
    'Are you sure to generate Arrival Notice for this shipment?',
  'shipment.generated_delivery_notice.success': 'Successfully generated Delivery Order',
  'shipment.consignee': 'Consignee',

  'shipment.todo.add.title': 'Add To-do',
  'shipment.todo.assign': 'Assign Operator',
  'shipment.todo.arrival_notice': 'Arrival Notice',
  'shipment.todo.soc_guarantee': 'SOC Guarantee',
  'shipment.todo.release_order': 'Release Order',
  'shipment.todo.hbl': 'HB/L',
  'shipment.todo.ams_isf': 'AMS/ISF Info',
  'shipment.todo.it': 'I.T. Info',
  'shipment.todo.customs_clearance': 'Customs Entry',
  'shipment.todo.confirm_customs_released': 'Confirm Customs Released',
  'shipment.todo.delivery_order': 'Delivery Order',
  'shipment.todo.confirm_delivery_order_received': 'Confirm Delivery Order Received',
  'shipment.todo.release_status': 'Release Status',
  'shipment.todo.carrier_release': 'Carrier Release',
  'shipment.todo.confirm_carrier_released': 'Confirm Carrier Released',
  'shipment.todo.confirm_terminal_released': 'Confirm Terminal Released',
  'shipment.todo.booking_confirmation': 'Booking Confirmation',
  'shipment.todo.delivered': 'Delivered',
  'shipment.todo.pickup_number': 'Pickup Number',
  'shipment.todo.pickup_last_free_date': 'Pickup Last Free Date',
  'shipment.todo.container_return': 'Container Return',
  'shipment.todo.confirm_container_returned': 'Confirm Container Returned',
  'shipment.todo.container_pickup': 'Container Pickup',
  'shipment.todo.confirm_container_pickedup': 'Confirm Container Picked Up',
  'shipment.todo.container_return_free_date': 'Container Return Free Date',
  'shipment.todo.terminal_free_date': 'Enter Last Free Date',
  'shipment.todo.moving': 'Train',
  'shipment.todo.mbl': 'MB/L',
  'shipment.todo.todo_due_date': 'To-do Due Date',
  'shipment.todo.completed_at': 'Completed At',
  'shipment.todo.title': 'The following shipments needs your attention:',
  'shipment.todo.accept_payment': 'Accept Payment',
  'shipment.todo.confirm_payment': 'Confirm Payment',
  'shipment.todo.original_bl_received': 'Original BL Received',
  'shipment.todo.original_bl_sent': 'Original BL Sent',
  'shipment.todo.telex_release_received': 'Telex Release Received',
  'shipment.todo.delete.confirm.title': 'Are you sure delete this to-do?',
  'shipment.todo.delete.success': 'successfully Deleted To-do',
  'shipment.document.field.type': 'Type',
  'shipment.document.field.for_contact': 'For Contact',
  'shipment.document.field.files': 'File',
  'shipment.document.field.belongs_to': 'Belongs to B/L',
  'shipment.document.field.belongs_to.required': 'Please choose this document belongs to.',
  'shipment.document.field.belongs_to.placeholder': 'Please choose this document belongs to',
  'shipment.container.title': 'Container',
  'shipment.containers.title': 'Containers',
  'shipment.freight.title': 'Freight',

  'shipment.document.type': 'Type',
  'shipment.document.type.hbl': 'HB/L',
  'shipment.document.type.mbl': 'MB/L',
  'shipment.document.type.bl': 'Bill of Lading',
  'shipment.document.type.air_entry': 'Air Entry',
  'shipment.document.type.arrival_notice': 'Arrival Notice',
  'shipment.document.type.carrier_arrival_notice': 'Carrier Arrival Notice',
  'shipment.document.type.booking_confirmation': 'Booking Confirmation',
  'shipment.document.type.air_booking_confirmation': 'Air Booking Confirmation',
  'shipment.document.type.customs_declaration': 'Customs Declaration',
  'shipment.document.type.air_delivery_order': 'Air Delivery Order',
  'shipment.document.type.delivery_order': 'Delivery Order',
  'shipment.document.type.mbl_manifest': 'Manifest',
  'shipment.document.type.mbl_dev_seg': 'DEV/SEG',
  'shipment.document.type.delivery_order_customs': 'Delivery Order (Customs)',
  'shipment.document.type.delivery_receipt': 'Delivery Receipt',
  'shipment.document.type.dock_receipt': 'Dock Receipt',
  'shipment.document.type.lcl_devanning_notice': 'LCL Devanning Notice',
  'shipment.document.type.invoice': 'Invoice',
  'shipment.document.type.invoice_confirmation': 'Shipment Invoice',
  'shipment.document.type.profit_report': 'Profit Report',
  'shipment.document.type.tax_report': 'Tax Report',
  'shipment.document.type.payment_voucher': 'Accounting Voucher',
  'shipment.document.type.statement': 'Statement',
  'shipment.document.type.payment_plan': 'Payment Plan',
  'shipment.document.type.local_statement': 'Local Statement',
  'shipment.document.type.release_order': 'Release Order',
  'shipment.document.type.soc_guarantee': 'SOC Guarantee',
  'shipment.document.type.other': 'Other',
  'shipment.document.type.ocean_export_mbl': 'Ocean Export MBL',
  'shipment.document.type.ocean_export_hbl': 'Ocean Export HBL',
  'shipment.document.type.ocean_export_hbl_confirmation': 'Ocean Export HBL Confirmation',
  'shipment.document.type.ocean_export_hbl_release': 'Ocean Export HBL Release',
  'shipment.document.type.ocean_export_hbl_blank': 'Ocean Export HBL Blank',
  'shipment.document.type.ocean_export_mbl_confirmation': 'Ocean Export MBL Confirmation',
  'shipment.document.type.ocean_export_mbl_release': 'Ocean Export MBL Release',
  'shipment.document.type.ocean_export_mbl_blank': 'Ocean Export MBL Blank',
  'shipment.document.type.hawb_confirmation': 'HAWB Confirmation',
  'shipment.document.type.hawb_blank': 'HAWB Blank',
  'shipment.document.type.mawb_confirmation': 'MAWB Confirmation',
  'shipment.document.type.mawb_blank': 'MAWB Blank',
  'shipment.document.type.credit_note': 'Credit Note',
  'shipment.document.type.debit_note': 'Debit Note',
  'shipment.document.type.packing_list': 'Packing List',
  'shipment.document.type.certificate_of_origin': 'Certificate of Origin',
  'shipment.document.type.departure_notice': 'Departure Notice',
  'shipment.document.type.cargo_receipt': "Forwarder's Cargo Receipt",
  'shipment.document.type.pickup_order': 'Pickup Order',
  'shipment.document.type.bl_instruction': 'BL Instruction',
  'shipment.document.type.receiving_notice': 'Receive Notice',
  'shipment.document.type.booking_note': 'Booking Note',
  'shipment.document.type.hbl_confirmation': 'HBL Confirmation',
  'shipment.document.type.bank_receipt': 'Bank Receipt',
  'shipment.document.type.transaction_voucher': 'Transaction Voucher',
  'shipment.document.type.mbl_release': 'MBL Release',
  'shipment.document.type.hbl_release': 'HBL Release',
  'shipment.document.type.aci_report': 'ACI Report',
  'shipment.document.type.ams_report': 'AMS Report',
  'shipment.document.type.isf_report': 'ISFReport',
  'shipment.document.type.quotation_report': 'Quotation',
  'shipment.document.type.mbl_blank': 'Blank MBL',
  'shipment.document.type.hbl_blank': 'Blank HBL',
  'shipment.document.type.statement_group_by_shipment': 'Statement Group By Shipment',
  'shipment.document.type.air_arrival_notice': 'Air Arrival Notice',
  'shipment.document.type.delivery_order_ocean_import': 'Delivery Order (Ocean Import)',
  'shipment.document.type.delivery_order_ocean_export': 'Delivery Order (Ocean Export)',
  'shipment.document.type.delivery_order_air_import': 'Delivery Order (Air Import)',
  'shipment.document.type.delivery_order_air_export': 'Delivery Order (Air Export)',
  'shipment.document.empty': 'No Documents',
  'shipment.document.update.success': 'Document updated',
  'shipment.document.choose_documents_first': 'Choose Documents First',
  'shipment.document.payments_reminder': 'Payments Reminder',
  'shipment.document.invoice_bill': 'Invoice/Bill#',
  'shipment.tab.documents': 'Documents',
  'shipment.tab.memos': 'Memos',
  'shipment.tab.info': 'Info',
  'shipment.tab.accounting': 'Accounting',
  'shipment.tab.status': 'Status',
  'shipment.tab.receivables': 'Receivables',
  'shipment.tab.payables': 'Payables',
  'shipment.tab.tracking': 'Tracking',
  'shipment.memo.add.success': 'Memos added to shipment successfully.',
  'shipment.document.add.success': 'Documents added to shipment successfully.',
  'shipment.lock.success': 'Locked successfully',
  'shipment.unlock.success': 'Unlocked successfully',
  'shipment.notifications.lock.title': 'Shipment Locked',
  'shipment.notifications.lock.description': 'Shipment is locked by {name}',
  'shipment.tools.mvl_confirmation': 'MBL Confirmation',
  'shipment.email.to': 'To',
  'shipment.email.cc': 'Cc',
  'shipment.email.showCc': 'Show Cc',
  'shipment.email.documents': 'Documents',
  'shipment.email.title': 'Email documents',
  'shipment.email.from': 'Send from',
  'shipment.email.field.message': 'Message',
  'shipment.email.field.message.placeholder': 'Enter message',
  'shipment.email.field.subject': 'Subject',
  'shipment.email.field.subject.placeholder': 'Enter subject',
  'shipment.email.placeholder': 'Enter email addresses',
  'shipment.email.emailed_documents.success': 'Successfully Emailed Documents',
  'shipment.email.generated_document.success': 'Successfully generated document',

  'shipment.memo.field.subject': 'Subject',
  'shipment.memo.field.subject.placeholder': 'Please input subject',
  'shipment.memo.field.content': 'Content',
  'shipment.memo.field.created_by': 'Created By',
  'shipment.memo.field.created_at': 'Created At',
  'shipment.memo.field.updated_at': 'Updated At',
  'shipment.memo.field.action': 'Action',
  'shipment.memo.edit_memo': 'Edit Memo',
  'shipment.memo.empty': 'No Memo',
  'shipment.memo.add_memo': 'Add Memo',
  'shipment.memo.delete_memo': 'delete_memo',
  'shipment.memo.added.success': 'Successfully added Memo',
  'shipment.memo.deleted.success': 'Successfully deleted Memo',
  'shipment.memo.delete.confirm.title': 'Are you sure to delete this memo?',

  'shipment.contact.missing_contact': 'Missing Contact',
  'shipment.contact.add_to_contacts': 'Add to contacts',
  'shipment.contact.link_to_existing_contact': 'Link to existing contact',
  'shipment.contact.link_contact': 'Link contact',
  'shipment.contact.select_contact': 'Select a contact',
  'shipment.contact.placeholder': 'Please select a contact',
  'shipment.contact.add.confirm': 'Do you want to add it to your contacts?',
  'shipment.complete_info.tooltip':
    'Please complete the following info before submitting this shipment',
  'shipment.add_items_from_template': 'Add items from template',
  'shipment.charge_items_added': 'Charge items added',
  'shipment.generated_soc_guarantee.success': 'Successfully generated SOC Guarantee',
  'shipment.soc_guarantee': 'SOC Guarantee',
  'shipment.hbl_confirmation': 'HBL Confirmation',
  'shipment.hbl_release': 'HBL Release',
  'shipment.hbl_blank': 'HBL Blank',
  'shipment.hawb_confirmation': 'HAWB Confirmation',
  'shipment.hawb_blank': 'HAWB Blank',
  'shipment.mbl_blank': 'MBL Blank',
  'shipment.mbl_release': 'MBL Release',
  'shipment.mbl_release.request': 'Request MBL Release',
  'shipment.mbl_release.request.confirm': 'Do you want to request release approval?',
  'shipment.add_hbl': 'Add HB/L',
  'shipment.add_hawb': 'Add HAWB',
  'shipment.add_sub_hawb': 'Add Sub HAWB',
  'shipment.total_charge': 'Total Charge',
  'shipment.accounting.sidebar.title': 'Shipment Totals',
  'shipment.charge_item.create_with_bill':
    'Would you like to create a bill with the following charge items?',
  'shipment.charge_item.create_with_invoice':
    'Would you like to create an invoice with the following charge items?',
  'shipment.charge_item.create_with_credit_note':
    'Would you like to create a credit note with the following charge items?',
  'shipment.charge_item.create_with_debit_note':
    'Would you like to create a debit note with the following charge items?',
  'shipment.accounting.title.cost': 'Cost',
  'shipment.accounting.title.revenue': 'Revenue',
  'shipment.accounting.title.bills': 'Bills',
  'shipment.accounting.charge_item.deleteAll.confirm': 'Are your sure to delete all items?',
  'shipment.accounting.charge_item.approveAll.confirm': 'Are your sure to approve all items?',
  'shipment.accounting.charge_item.re_create_charge_items': 'Re-create charge items',
  'shipment.accounting.bill_invoice.void.confirm':
    'Are you sure you want to void this {type} {code}?',
  'shipment.mark_down.confirm': 'Are you sure to mark this as done?',
  'shipment.message.copy_info_from_isf': 'Do you want to copy shipment info from ISF?',
  'shipment.message.copy_info_from_ams': 'Do you want to copy shipment info from AMS?',
  'shipment.file_details': 'File Details',
  'shipment.shipment_parties': 'Shipment Parties',
  'shipment.ssl_specifics': 'SSL Specifics',
  'shipment.security_filing': 'Security Filing',
  'shipment.workflow': 'Workflow',
  'shipment.suggestion.reply_email': 'Reply Email',
  'shipment.suggestion.create_isf': 'Create a new ISF',
  'shipment.suggestion.create_quotation': 'Create a new quotation',
  'shipment.suggestion.associate_shipment': 'Associate to a shipment',
  'shipment.suggestion.create_shipment': 'Create a new shipment',
  'shipment.suggestion.update_shipment': 'Update an existing shipment',
  'shipment.suggestion.add_memo': 'Would you like to add the following memo',
  'shipment.suggestion.add_document': 'Would you like to add the following document',
  'shipment.suggestion.handle_document': 'Handle this document',
  'shipment.suggestion.add_shipment_documents': 'Add documents to a shipment',
  'shipment.suggestion.bl_type.direct_master': 'Direct Master',
  'shipment.suggestion.bl_type.master_with_hbl': 'Master with a HBL',
  'shipment.suggestion.bl_type.hbl_to_existing_mbl': 'HBL to an existing MBL',
  'shipment.message.confirm_push_edi': 'Are you sure to push this shipment using EDI?',
  'shipment.message.confirm_push_local_edi_updates':
    'Are you sure to push new invoices to {mbl_no}?',
  'shipment.title.contact_mapping': 'Contact Mapping',
  'shipment.list_title.negative': 'Negative Profit Shipments',
  'shipment.document.extract_shipment_from_document': 'Update shipment info',
  'shipment.hbl.move.title': 'Move HB/L To',
  'shipment.field.packing_certificate_no': 'Packing Certificate No.',
  'shipment.field.documentation_officer': 'Documentation Officer',
  'shipment.field.tonnage': 'Tonnage',
  'shipment.field.trade_terms': 'Trade Terms',
  'shipment.field.dangerous_goods': 'Dangerous Goods',
  'shipment.field.co_operators': 'Co-Operators',
  'shipment.action.transfer_to': 'Transfer To {type}',
  'shipment.field.is_connecting_flight': 'Connecting Flight',
  'shipment.field.connecting_flight.code': 'Code',
  'shipment.field.connecting_flight.airport': 'Airport',
  'shipment.field.connecting_flight.arrival_date': 'Arrival Date/Time',
  'shipment.field.connecting_flight.departure_date': 'Departure Date/Time',
  'shipment.field.connecting_flight.flight_number': 'Flight No,',
  'shipment.field.connecting_flight.airline': 'Airline',
  'shipment.field.detail_info': 'Detail Info',
  'shipment.field.container_count': 'Container Count',
  'shipment.field.package': 'No. of Packages',
  'shipment.field.container_no': 'Container No.',
  'shipment.field.available_pickup_date': 'Available Pickup Date',
  'shipment.field.measurement': 'Measurement(CBM)',
  'shipment.field.service_term': 'Service Term',
  'shipment.field.ship_mode': 'Ship Mode',
  'shipment.field.container_info': 'Container Info',
  'shipment.field.ar_to_ap': 'A/R to A/P',
  'shipment.field.port_of_discharge': 'Port of Discharge',
  'shipment.field.overseas_sales': 'Overseas Sales',
  'shipment.field.port_of_loading': 'Port of Loading',
  'shipment.field.zipcode': 'Zipcode',
  'shipment.field.qtoshipment': 'Quotation Shipment Request',
  'shipment.field.cargo_control_no': 'Cargo Control No.',
};
