import { UserType, UserRoles, UserData } from '@/common/type';
import { find } from 'lodash';
import jsonata from 'jsonata';
import { getShopSetting } from './utils';

export default function(userData: UserData) {
  const defaultPrivateRole =
    userData && userData.type && [UserType.member, UserType.owner].includes(userData.type);
  const permissions = {};
  userData?.shop_roles_data?.map(({ name, effect = 'allow', condition = '$' }) => {
    permissions[name] = (data: any) => {
      let result = false;
      if (userData.shop_roles?.includes(UserRoles.admin)) {
        return true;
      }
      if (data) {
        (async () => {
          const expression = jsonata(condition);
          result = await expression.evaluate(data);
        })();
      } else {
        return effect === 'allow';
      }
      return effect === 'allow' ? result : !result;
    };
  });
  return {
    // router filter
    isAdmin: (userData && userData?.shop_roles?.includes(UserRoles.admin)) || false,
    isCustomer: userData?.type && [UserType.customer].includes(userData.type),
    isForwarder:
      !!userData && !!userData.type && ![UserType.agent, UserType.customer].includes(userData.type),
    memberRouterFilter: defaultPrivateRole,
    accountingRouteFilter:
      defaultPrivateRole &&
      (userData.shop_roles.includes(UserRoles.view_accounting) ||
        userData.shop_roles.includes(UserRoles.admin)),
    reportRouteFilter:
      defaultPrivateRole &&
      (userData.shop_roles.includes(UserRoles.view_report) ||
        userData.shop_roles.includes(UserRoles.admin)),

    chargeItemRouteFilter:
      defaultPrivateRole &&
      (userData.shop_roles.includes(UserRoles.approve_charge_item) ||
        userData.shop_roles.includes(UserRoles.admin)),

    bankReconcileRouteFilter:
      defaultPrivateRole &&
      (userData.shop_roles.includes(UserRoles.able_bank_reconcile) ||
        userData.shop_roles.includes(UserRoles.admin)),

    adminRouteFilter: userData && userData.type && userData.shop_roles.includes(UserRoles.admin),
    featureExcelHeader: !!find(userData?.shop?.settings, {
      name: 'feature_excel_header',
      enabled: true,
    }),
    featureMultiCurrencies: !!userData?.shop?.currencies && userData.shop.currencies.length > 1,
    featureAgingReport: getShopSetting(userData, 'feature_aging_report', 'message'),
    featureHideFirmCode: getShopSetting(userData, 'feature_hide_firm_code', 'enabled'),
    featureClassicShipmentAccountingTabOnly: getShopSetting(
      userData,
      'feature_classic_shipment_accounting_tab_only',
      'enabled',
    ),
    featureMultiOffice: getShopSetting(userData, 'feature_multi_offices', 'enabled'),
    featureFullChargeItemTemplate: getShopSetting(
      userData,
      'feature_full_chargeitem_template',
      'enabled',
    ),
    featureAutoISF: getShopSetting(userData, 'feature_auto_isf', 'enabled'),
    featureISF: getShopSetting(userData, 'feature_isf', 'enabled'),
    featureAMS: getShopSetting(userData, 'feature_ams', 'enabled'),
    featureMultiLanguage: getShopSetting(userData, 'feature_multi_language', 'enabled'),
    featureApprovalCenter: getShopSetting(userData, 'feature_approval', 'enabled'),
    featureTaxCodes: getShopSetting(userData, 'feature_tax_codes', 'enabled'),
    featureWHT: getShopSetting(userData, 'feature_wht', 'enabled'),
    featureAllowChangeInvCode: getShopSetting(userData, 'feature_allow_change_inv_code', 'enabled'),
    featureShipmentReleaseApproval: getShopSetting(
      userData,
      'feature_shipment_release_approval',
      'enabled',
    ),
    featureSimpleTransaction: getShopSetting(userData, 'feature_simple_transaction', 'enabled'),
    featurePendingTransactions: getShopSetting(userData, 'feature_pending_transactions', 'enabled'),
    featureExcelTemplateDownload: getShopSetting(
      userData,
      'feature_excel_template_download',
      'enabled',
    ),
    featureEmailFromUserAccount: getShopSetting(
      userData,
      'feature_email_from_user_account',
      'enabled',
    ),
    featureANDOEmailSubjectAdditions: getShopSetting(
      userData,
      'feature_ando_email_subject_additions',
      'message',
    ),
    featureBillDependents: getShopSetting(userData, 'feature_bill_dependents', 'enabled'),
    featureDisableContactQuickAdd: getShopSetting(
      userData,
      'feature_disable_contact_quick_add',
      'enabled',
    ),
    featureTempContactQuickAdd: getShopSetting(
      userData,
      'feature_temp_contact_quick_add',
      'enabled',
    ),
    featureDisableContactWarning: getShopSetting(
      userData,
      'feature_disable_contact_warning',
      'enabled',
    ),
    featureRestrictedAccountingDate: getShopSetting(
      userData,
      'feature_restricted_accounting_date',
      'enabled',
    ),
    featureDailyCashReport: getShopSetting(userData, 'feature_daily_cash_report', 'enabled'),
    featureCurrentAccountReport: getShopSetting(
      userData,
      'feature_current_account_report',
      'enabled',
    ),
    featureTieInvoiceDateToEtaEtd: getShopSetting(
      userData,
      'feature_tie_invoice_date_to_eta_etd',
      'enabled',
    ),
    featureContainerLWH: getShopSetting(userData, 'feature_container_lwh', 'enabled'),
    featureMiscUseETD: getShopSetting(userData, 'feature_misc_use_etd', 'enabled'),
    featureAlwaysOpenNewTab: getShopSetting(userData, 'feature_always_open_new_tab', 'enabled'),
    featureSameDayDueDate: getShopSetting(userData, 'feature_same_day_due_date', 'enabled'),
    featureFapiao: getShopSetting(userData, 'feature_fapiao', 'enabled'),
    featureSeparateARAPList: getShopSetting(userData, 'feature_separate_arap_list', 'enabled'),
    featurePaymentPlans: getShopSetting(userData, 'feature_payment_plans', 'enabled'),
    featureNotification: getShopSetting(userData, 'feature_notification', 'enabled'),
    featureCheckVersion:
      getShopSetting(userData, 'feature_check_version', 'enabled') === undefined
        ? true // if there is no such a setting, the default value
        : getShopSetting(userData, 'feature_check_version', 'enabled'),
    featureSubmitStatement: getShopSetting(userData, 'feature_submit_statement', 'enabled'),
    featureARAPDirectPayment: getShopSetting(userData, 'feature_arap_direct_payment', 'enabled'),
    featureEfreightAms: getShopSetting(userData, 'feature_efreight_ams', 'enabled'),
    featureEfreightIsf: getShopSetting(userData, 'feature_efreight_isf', 'enabled'),
    featureSimpleShipment: getShopSetting(userData, 'feature_simple_shipment', 'enabled'),
    featureEDIImport: getShopSetting(userData, 'feature_edi_import', 'enabled'),
    featureImport: getShopSetting(userData, 'feature_import', 'enabled'),
    featureTracking: getShopSetting(userData, 'feature_tracking', 'enabled'),
    featureSideMenuTrigger: getShopSetting(userData, 'feature_side_menu_trigger', 'enabled'),
    featureTodoGroupByShipment: getShopSetting(
      userData,
      'feature_todo_group_by_shipment',
      'enabled',
    ),
    featureLegacyTransactionReconciliation: getShopSetting(
      userData,
      'feature_legacy_transaction_reconciliation',
      'enabled',
    ),
    featureBlockedLogin: getShopSetting(userData, 'feature_blocked_login', 'enabled'),
    featureQuotation: getShopSetting(userData, 'feature_quotation', 'enabled'),
    featureQuotationApproval: getShopSetting(userData, 'feature_quotation_approval', 'enabled'),
    featureQtoShipment: getShopSetting(userData, 'feature_qtoshipment', 'enabled'),
    featureContainerCost: getShopSetting(userData, 'feature_container_cost', 'enabled'),
    featureStayWithDetail: getShopSetting(userData, 'feature_stay_with_detail', 'enabled'),
    featureStatementDocumentOverride: getShopSetting(
      userData,
      'feature_statement_document_override',
      'message',
    ),
    featureMilestoneShipmentReport: getShopSetting(
      userData,
      'feature_milestone_shipment_report',
      'enabled',
    ),
    featureAutoReply: getShopSetting(userData, 'feature_auto_reply', 'enabled'),
    featureNewItemInbox: getShopSetting(userData, 'feature_new_item_inbox', 'enabled'),
    featurePDFAutoResizing: getShopSetting(userData, 'feature_pdf_auto_resizing', 'enabled'),
    featureInputNumberControls: getShopSetting(
      userData,
      'feature_input_number_controls',
      'enabled',
    ),
    monthlyClosing: getShopSetting(userData, 'feature_monthly_closing', 'message'),
    glCodeMapping: getShopSetting(userData, 'gl_code_mapping', 'message'),
    importMapping: getShopSetting(userData, 'feature_import', 'message'),
    listSettings: getShopSetting(userData, 'feature_lists_settings', 'message') || {},
    agingReportDueDateField: getShopSetting(userData, 'feature_aging_report_date_field', 'enabled'),
    viewAccounting:
      userData?.shop_roles?.includes(UserRoles.admin) ||
      userData?.shop_roles?.includes(UserRoles.view_accounting),
    viewReport:
      userData?.shop_roles?.includes(UserRoles.admin) ||
      userData?.shop_roles?.includes(UserRoles.view_report),
    availableCurrencies: userData?.shop?.currencies || [],
    featureEDI: getShopSetting(userData, 'feature_edi', 'enabled'),
    featureTruck: getShopSetting(userData, 'feature_truck', 'enabled'),
    featureYearEndClosing: getShopSetting(userData, 'feature_year_end_closing', 'enabled'),
    featureCopyLink: getShopSetting(userData, 'feature_copy_link', 'enabled'),
    featureShowVersionWarning: getShopSetting(userData, 'feature_show_version_warning', 'enabled'),
    featureOldChargeItemsUI: getShopSetting(userData, 'feature_old_charge_items_ui', 'enabled'),
    featureNewContainerUI: getShopSetting(userData, 'feature_new_container_ui', 'enabled'),
    featureMyContainerList: getShopSetting(userData, 'feature_my_container_list', 'enabled'),
    featureLockShipmentNumber: getShopSetting(userData, 'feature_lock_shipment_number', 'enabled'),
    featureCustomerPortal: getShopSetting(userData, 'feature_customer_portal', 'enabled'),
    featureDefaultAutoGenerateHblNo: getShopSetting(
      userData,
      'feature_default_auto_generate_hbl_no',
      'enabled',
    ),
    featureExternalCurrencyRate: getShopSetting(
      userData,
      'feature_external_currency_rate',
      'enabled',
    ),
    featureShowSalesTypeMBL: getShopSetting(userData, 'feature_show_sales_type_mbl', 'enabled'),
    featureDefaultDashboard: getShopSetting(userData, 'feature_default_dashboard', 'message'),
    featureCustomUnits: getShopSetting(userData, 'feature_custom_units', 'message'),
    featurePDFLastModifiedAsDefaultSource: getShopSetting(
      userData,
      'feature_pdf_last_modified_as_default_source',
      'enabled',
    ),
    featureARAPPostDate: getShopSetting(userData, 'feature_arap_post_date', 'enabled'),
    featureTableFixedHeader: getShopSetting(userData, 'feature_table_fixed_header', 'enabled'),
    featureGroupMenu: getShopSetting(userData, 'feature_group_menu', 'enabled'),
    featureNoBlockedDateRestrictions: getShopSetting(
      userData,
      'feature_no_blocked_date_restrictions',
      'enabled',
    ),
    featureCollapseOnQuery:
      getShopSetting(userData, 'feature_collapse_on_query', 'enabled') === undefined
        ? true // if there is no such a setting, the default value
        : getShopSetting(userData, 'feature_collapse_on_query', 'enabled'),
    // access control
    ...permissions,
    account_reports_or_aging_report:
      userData?.shop_roles?.includes(UserRoles.admin) ||
      userData?.shop_roles?.includes(UserRoles.account_aging_report) ||
      userData?.shop_roles?.includes(UserRoles.account_reports) ||
      userData?.shop_roles?.includes(UserRoles.view_report),
  };
}
