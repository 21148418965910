export default {
  'notification.empty': 'You have viewed all notifications',
  'notification.load.more': 'Load More',
  'notification.message.approval.approved': 'The {module} you submitted has been approved',
  'notification.message.approval.submitted': 'A new {module} needs to be approval',
  'notification.message.approval.rejected': 'The {module} you submitted has been rejected',
  'notification.message.statement.added_fapiao': 'A new fapiao has been added to statement {code}',
  'notification.message.arap.import.success': 'AR/AP import successful!',
  'notification.message.arap.import.error': 'AR/AP import failed!',
  'notification.message.shipment.import.success': 'Shipment import successful!',
  'notification.message.shipment.import.error': 'Shipment import failed!',
  'notification.message.update.available': 'New version available',
  'notification.message.update.refresh': 'Refresh',
  'notification.message.shipment.update': 'Shipment {shipment_number} has been updated',
};
