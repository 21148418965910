export default {
  'admin.employee.create.success': 'Successfully added new user',
  'admin.employee.update.success': 'Successfully updated user',
  'admin.employee.disable.success': 'Successfully disabled user',
  'admin.employee.enable.success': 'Successfully enabled user',
  'admin.color.update.success': 'Successfully updated colors',
  'admin.employee.disable.confirm': 'Are you sure you want to disable this user?',
  'admin.employee.enable.confirm': 'Are you sure you want to enable this user?',
  'admin.employee.filed.name': 'Name',
  'admin.employee.filed.type': 'Type',
  'admin.employee.filed.permissions': 'Permissions',
  'admin.employee.filed.type.placeholder': 'Please Select type',
  'admin.employee.title': 'Employee',
  'admin.employee.add.disabled': 'Please contact SmartForwarder to add new user',
  'admin.tracking_users.create.success': 'Successfully added new tracking user',
  'admin.tracking_users.update.success': 'Successfully updated tracking user',
  'admin.tracking_users.disable.confirm': 'Are you sure you want to disable this tracking user?',
  'admin.tracking_users.disable.success': 'Successfully disabled tracking user',
  'admin.tracking_users.enable.confirm': 'Are you sure you want to enable this tracking user?',
  'admin.tracking_users.enable.success': 'Successfully enabled tracking user',
  'admin.tracking_users.add.title': 'Add Tracking User',
  'admin.tracking_users.edit.title': 'Edit Tracking User',
  'admin.tracking_users.details.title': 'Tracking User Details',
  'admin.tracking_users.title': 'Tracking Users',
  'admin.tracking_users.part_of': 'Part of',
  'admin.ams_agent.create.success': 'Successfully added new AMS agent',
  'admin.ams_agent.update.success': 'Successfully updated AMS agent',
  'admin.ams_agent.disable.confirm': 'Are you sure you want to disable this AMS agent?',
  'admin.ams_agent.disable.success': 'Successfully disabled AMS agent',
  'admin.ams_agent.enable.confirm': 'Are you sure you want to enable this AMS agent?',
  'admin.ams_agent.enable.success': 'Successfully enabled AMS agent',
  'admin.ams_agent.add.title': 'Add AMS Agent',
  'admin.ams_agent.edit.title': 'Edit AMS Agent',
  'admin.ams_agent.details.title': 'AMS Agent Details',
  'admin.ams_agent.title': 'AMS Agents',
  'admin.ams_agent.part_of': 'Part of',
};
